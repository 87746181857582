import * as React from 'react';
import { Button, FormControl, TextField } from '@material-ui/core';
import { CommonStyles, getModalStyle, getModalClasses, globalColors } from '../../hooks/styles';
import { useMediaQuery } from 'react-responsive';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { SelectBox } from '../../components/selectBox';
import { connect } from 'react-redux';
import { OrderActions } from '../../redux/actions';
import Loading from '../../components/loading';
import { Autocomplete } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';

const CustomerOrderRequestModal: React.FC<any> = (props: any) => {
	const commonStyles = CommonStyles();
	const modalClasses = getModalClasses();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const [selectedCustomer, setSelectedCustomer] = React.useState(props.selectedCustomer || null)
	const [customerProductsLoading, setCustomerProductsLoading] = React.useState<any>(false);
	const [customerProductsError, setCustomerProductsError] = React.useState<string>('');
	const [customerProducts, setCustomerProducts] = React.useState<any>([]);
	const [orderLines, setOrderLines] = React.useState<any[]>([{}]);
	const [customerNotes, setCustomerNotes] = React.useState<string>('');
	const [customerNotesError, setCustomerNotesError] = React.useState<string>('');
	const [errors, setErrors] = React.useState<any>({});

	const getFilter = () => {
		const filter: any = {
			offset: props.pagination.offset,
			limit: props.globalRowsPerPage,
			where: {},
			order: props.sortOrder ? [props.sortOrder] : ['created_at DESC'],
		};
		const andFilters = []
		if (selectedCustomer) {
			andFilters.push({ customerId: selectedCustomer.customerId })
		}
		const status = props.selectedFilterValues?.ORDER_REQUEST_STATUS || null
		if (status) {
			andFilters.push({ status: status })
		}
		if (andFilters.length > 0) {
			filter.where.and = andFilters
		}
		return filter;
	};

	const submit = () => {
    if (validateForm()) {
      return
    }

		let orderPayload = getOrderPayload()
		props.submitOrderRequest({
			body: orderPayload,
			filter: [{key: 'filter', value: JSON.stringify(getFilter())}]
		})
		props.close(false);
	};

	const validateForm = () => {
		let validationResult = false
		let formErrors: any = {}
		orderLines.forEach((orderLine: any, index: number) => {
			const lineErrors: any = {}
			if (!orderLine.product ) {
				lineErrors['product'] = 'Please select a valid product ID.'
			}
			if (!orderLine.requestedQuantity || isNaN(orderLine.requestedQuantity) || orderLine.requestedQuantity < 1) {
				lineErrors['requestedQuantity'] = 'Invalid qty.'
			}
			if (!orderLine.shippingMethod ) {
				lineErrors['shippingMethod'] = 'Please select a shipping method.'
			}
			formErrors[index] = lineErrors
		})
		setErrors(formErrors)
		validationResult = Object.keys(formErrors).some((index: string) => Object.keys(formErrors[index]).length > 0)
		if (!customerNotes) {
			setCustomerNotesError('Please describe why this order is being requested.')
			validationResult = true
		}
		return validationResult 
	}

	const getOrderPayload = () => {
		return orderLines.map((orderLine: any) => (
			{
				customerId: selectedCustomer.customerId,
				deviceType: orderLine.product.deviceType,
				productId: orderLine.product.productId,
				requestedQuantity: +orderLine.requestedQuantity,
				shippingMethod: orderLine.shippingMethod,
				customerNotes: customerNotes
			}
		))
	}

	const handleInputChange = (value: any, field: string, index: number) => {
		let orderLinesCopy = [...orderLines]
		orderLinesCopy[index][field] = value
		setOrderLines(orderLinesCopy)
		let errorsCopy = {...errors}
		if (errorsCopy[index]) {
		delete errorsCopy[index][field]
		setErrors(errorsCopy)
		}
	}

	const getCustomerProducts = () => {
		setCustomerProducts([])
		setCustomerProductsError('')
		setCustomerProductsLoading(true)
		props.getCustomerProducts({
			customerId: selectedCustomer.customerId,
			params: [],
			onSuccess: (result: any) => {
			setCustomerProducts(result.restockRecommendations)
			setCustomerProductsLoading(false)
			},
			onError: (error: string) => {
			setCustomerProductsError(error)
			setCustomerProductsLoading(false)
			}
		})
	}

	React.useEffect(() => {
		console.log('CUST', selectedCustomer)
		if (selectedCustomer) {
			getCustomerProducts()
			setCustomerProducts([])
			setCustomerProductsError('')
		}
	}, [selectedCustomer])

	return (
		<div
			style={{
				...getModalStyle(),
				width: isDesktopOrLaptop ? 'auto' : '90%',
				maxHeight: 600,
				maxWidth: 750,
				overflow: 'scroll',
			}}
			className={modalClasses.paper}
		>
			<div
				style={{ position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}
				onClick={() => props.close(false)}
			>
				<CancelIcon />
			</div>
			<h2>
				Order Request For {selectedCustomer ? `${selectedCustomer.name} (${selectedCustomer.customerId})` : ''} 
				{selectedCustomer && <EditIcon 
					onClick={()=> {
						setSelectedCustomer(null)
						setCustomerProducts([])
						setCustomerProductsError('')
					}} 
				/>}
			</h2>
			{!selectedCustomer && <div style={{margin: '25px 0 0 0'}}>
				<FormControl variant="outlined" style={{ marginRight: 10 }}>
          <Autocomplete
            id="customer-combo-box"
            options={props.tenants}
            getOptionLabel={(option: any) => '(' + option.customerId + ') ' + option.name}
            style={{ width: 280 }}
            onChange={(e: any, value: any) => {
              setCustomerProducts([])
              setCustomerProductsError('')
							setSelectedCustomer(value)
              if (!value) return
            }}
            renderInput={(params: any) => (
              <TextField
                value={selectedCustomer}
                {...params}
                label="Search Customers"
                variant="outlined"
                error={errors.customer ? true : false}
                helperText={errors.customer ? 'Please choose a customer.' : ''}
              />
            )}
          />
        </FormControl>
			</div>}

			{customerProductsLoading && <Loading message="" />}
			{customerProductsError && <p style={{ color: 'red' }}>{customerProductsError}</p>}
			{customerProducts.length > 0 && <>{orderLines.map((orderLine: any, index: number) => (
				<div key={index} style={{ margin: '25px 0 0 0' }}>
					{orderLine.product?.outstandingOrders > 0 && <div style={{marginBottom: 5, color: 'red'}}>{`This product currently has ${orderLine.product.outstandingOrders} outstanding order(s).`}</div>}
					<div style={{ margin: '0' }}>
						<FormControl variant="outlined" style={{ marginRight: 10 }}>
							<SelectBox
							inputLabel={'Select Product'}
							emptyItemLabel={'Choose'}
							style={{ width: 200 }}
							selected={orderLine.product || ''}
							listItems={customerProducts.map((customerProduct: any) => ({label: `${customerProduct.productName} (${customerProduct.productId})`, value: customerProduct}))}
							error={errors[index]?.product ? true : false}
							errorText={errors[index]?.product || ''}
							required
							onChangeItem={(value: any) => {
								handleInputChange(value, 'product', index)
							}}
							/>
						</FormControl>
						<FormControl variant="outlined" style={{ marginRight: 10 }}>
							<TextField
							variant="outlined"
							type="number"
							label="Qty"
							style={{ width: 80 }}
							required
							InputProps={{ inputProps: { min: 0, step: 1 } }}
							value={(orderLine.requestedQuantity && orderLine.requestedQuantity >= 0) ? orderLine.requestedQuantity : ''}
							error={errors[index]?.requestedQuantity ? true : false}
							helperText={errors[index]?.requestedQuantity || ''}
							onChange={event => {
								handleInputChange(event.target.value, 'requestedQuantity', index)
							}}
							/>
						</FormControl>
						<FormControl variant="outlined">
							<SelectBox
								style={{ width: 200 }}
								inputLabel={'Shipping Method'}
								listItems={[
									{ label: 'Standard Overnight (by 10:30AM)', value: 'Standard Overnight' },
									{ label: 'First Overnight (by 8:30AM)', value: 'First Overnight' },
									{ label: 'Saturday Delivery (by 10:30AM)', value: 'Saturday Delivery' },
								]}
								selected={orderLine.shippingMethod || 'Standard Overnight'}
								error={errors[index]?.shippingMethod ? true : false}
								errorText={errors[index]?.shippingMethod || ''}
								required
								onChangeItem={(value: any) => {
									handleInputChange(value, 'shippingMethod', index)
								}}
							/>
						</FormControl>
						<FormControl style={{ verticalAlign: 'middle', height: (index === 0 || index < orderLines.length - 1) ? '0' : 'auto' }}>
							{index === orderLines.length - 1 && <AddCircleIcon
							style={{ color: globalColors.FFF_BLUE, cursor: 'pointer' }}
							onClick={() => {
								setOrderLines([...orderLines, {}])
							}} />}
							{index !== 0 && <RemoveCircleIcon
							style={{ color: 'grey', cursor: 'pointer' }}
							onClick={() => {
								let orderLinesCopy = [...orderLines]
								orderLinesCopy.splice(index, 1)
								setOrderLines(orderLinesCopy)
							}} />}
						</FormControl>
					</div>
				</div>
				))}
				<div style={{ margin: '25px 0 25px 0' }}>
					<FormControl variant="outlined" style={{ marginRight: 10 }}>
						<TextField
							variant="outlined"
							label="Reason for Order Request"
							style={{ width: 530 }}
							value={customerNotes || ''}
							required
							placeholder='Please advise if there is an active patient need, specific delivery instructions, etc'
							error={customerNotesError ? true : false}
							helperText={customerNotesError || ''}
							onChange={event => {
								setCustomerNotes(event.target.value)
							}}
						/>
					</FormControl>
				</div>
				<p style={{margin: 0, fontSize: 12, color: 'grey'}}>
					DISCLAIMER: RNI hours are 8AM EST to 4:30PM PST Monday through Friday. If
					you are submitting your request after hours, your request will be
					reviewed the following business day. Order cut-off time is 1PM PST. 
					If your request is submitted prior to this time, we will review
					your request for order placement that same day. All orders
					submitted after 1PM PST may not be reviewed and/ or placed until
					the following business day. If you have any questions please call
					855-544-2122 or email <a href="mailto:customersupport@rightnowinventory.com">customersupport@rightnowinventory.com</a>.
					If you have after-hours urgent needs, please call 800-843-7477.
				</p>
				<div style={{ margin: '25px 0 0 0' }}>
					<FormControl>
						<Button
							type="button"
							className={commonStyles.searchButton}
							variant="contained"
							color="primary"
							onClick={submit}
						>
							SUBMIT
						</Button>
					</FormControl>
				</div>
			</>}
    	</div>
	);
};

const mapStateToProps = (state: any) => ({
	selectedCustomer: state.ui.selectedCustomer,
	tenants: state.tenants?.tenantsList?.result || [],
	selectedFilterValues: state.ui.selectedFilterValues,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCustomerProducts: (payload: any) => dispatch(OrderActions.getCustomerProductsForOrder(payload)),
	submitOrderRequest: (payload: any) => dispatch(OrderActions.submitOrderRequest(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrderRequestModal);
