import React from "react";
import { getModalClasses, getModalStyle, globalColors } from "../../../hooks/styles";
import CancelIcon from '@material-ui/icons/Cancel';
import { Grid } from "@material-ui/core";
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { UserTimezoneFormat, valueMapper } from "../../../hooks/functions";


const DashboardTableChartRendering: React.FC<any> = props => {

    const modalClasses = getModalClasses();
    const [modalStyle] = React.useState(getModalStyle());
    const [columns, setColumns] = React.useState(props.settings?.columns);
    const [records, setRecords] = React.useState(props.records);
    const [tableHeight, setTableHeight] = React.useState(0)

    React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 40; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

    const handleClose = () => {
        props.closeChart();
    };

    React.useEffect(() => {
        let columns = props.settings?.columns;

        if (columns) {
            // Column names to add settings to
            const convertDateColumns = ['startDate', 'endDate', 'updatedAt', 'createdAt'];
            columns = columns.map((el: { [key: string]: any; }) => {
                if (convertDateColumns.includes(el.name)) {
                    // Convert timestamps to user timezone
                    el.options = {
                        customBodyRender: (value: any) => {
                            return UserTimezoneFormat(props.properties, value);
                        }
                    };
                }
                if (el.textFormat === 'link') {
                    el.options = {
                        customBodyRender: (value: any) => {
                            return value ? <a target="_BLANK" href={`https://www.fedex.com/fedextrack/?trknbr=${value}`}>Track Order</a> : 'Not Shipped Yet'
                        }
                    }
                }

                if (!el.options) {
                    el.options = {
                        customBodyRender: (value: any) => {
                            return value ? value : 'N/A'
                        }
                    }
                }
                return el;
            })
            setColumns(columns);
        }

    }, [props.settings, props.properties]);

    React.useEffect(() => {
        let records = props.records;

        if (records) {
            // Column names to add settings to
            const cabinetTypes = ['EM', 'RFID', 'VISION', 'WEIGHT', 'Virtual'];

            records = records.map((el: { [key: string]: any; }) => {
                if (el?.cabinetType && cabinetTypes.includes(el.cabinetType)) {
                    // Convert cabinet type to name
                    el.cabinetType = valueMapper(el.cabinetType, 'cabinetTypes');
                }
                return el;
            })
            setRecords(records);
        }

    }, [props.records]);

    return (
        <div style={props.settings?.modalPopUp ? { ...modalStyle, maxWidth: 1200 } : {}} className={props.settings?.modalPopUp ? modalClasses.paper : ''}>
            {props.settings?.modalPopUp && <div className={modalClasses.closeBtn} onClick={handleClose}>
                <CancelIcon color="disabled"></CancelIcon>
            </div>}
            <div>
                <div id="simple-modal-title" style={{ color: '#333', fontSize: 18, marginTop: 10, marginBottom: 1 }}>
                    {props.settings?.displayName}
                </div>
                <Grid container>
                    <Grid item xs={12}>
                        <MUIDataTable
                            data={records || []}
                            columns={columns || []}
                            options={{
                                elevation: 0,
                                filterType: 'dropdown',
                                responsive: 'simple',
                                filter: false,
                                search: false,
                                download: false,
                                print: false,
                                selectableRows: 'none',
                                viewColumns: false,
                                tableBodyHeight: tableHeight + 'px', // Set the height for the table body
                                setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
                                    const status = props.records[dataIndex].status;
                                    // Highlight rows
                                    if (status === 'In Progress') {
                                        return { style: { backgroundColor: globalColors.WARNING_YELLOW } };
                                    }
                                    if (['Failure', 'Error'].includes(status)) {
                                        return { style: { backgroundColor: globalColors.TABLE_WARNING } };
                                    }
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTableChartRendering);