import * as React from 'react';
import { Modal, Button, FormControl, Chip, Grid, Tooltip, IconButton, Menu, MenuItem, AppBar, Tabs, Tab } from '@material-ui/core';
import { UserRecord } from '../../redux/models/user/user';
import MUIDataTable from 'mui-datatables';
import { UsersStyles } from './users.styles';
import UserForm from './user-form.component';
import AuthUser from '../../redux/lib/authUser';
import { UserDetails } from './user-details';
import dataList from '../../constants/dataList';
import Loading from '../../components/loading';
import EmptyContent from '../../components/emptyContent';
import { useMediaQuery } from 'react-responsive';
import UserImportForm from './user-import.component';
import UserEmailActivity from './emailActivity.modal';
import ResendInvite from './resendInvite.modal';
import SetPassword from './setPassword.modal';
import DeleteUser from './deleteUser.modal';
import { initUserType, generateDataList, onDownloadUsers } from './users.helper';
import { tablePagination } from '../../hooks/functions/TablePagination';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { GetUserTimezone, UserTimezoneFormat } from '../../hooks/functions';
import EditIcon from '@material-ui/icons/Edit';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LockResetIcon from '@mui/icons-material/LockReset';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import ScheduledReportsContainer from '../scheduled-reports/scheduled-reports.container';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

interface UsersProps {
	authUser?: AuthUser;
	properties: any[];
	tenants: any[];
	usersList?: UserRecord[];
	totalUsers: any;
	isLoading: boolean;
	selectedCustomer: any;
	globalRowsPerPage: any;
	setGlobalRowsPerPage: (rowsPerPage: number) => void;
	searchTenants: (filter: any) => void;
	getUsersList: (payload: any | null) => void;
	resetTenants: () => void;
	setSelectedCustomer: (customer: any) => void;
	selectedFilterValues: any;
}

export const UsersComponent: React.FC<UsersProps> = props => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const [selectedTenant, setSelectedTenant] = React.useState(props.selectedCustomer || (null as any));
	const [modalOpen, setModalOpen] = React.useState(false);
	const [emailActivityModalOpen, setEmailActivityModalOpen] = React.useState(false);
	const [resendInviteModalOpen, setResendInviteModalOpen] = React.useState(false);
	const [setPasswordModalOpen, setTempPasswordModalOpen] = React.useState(false);
	const [deleteUserModalOpen, setDeleteUserModalOpen] = React.useState(false);
	const [importModalOpen, setImportModalOpen] = React.useState(false);
	const [createScheduledReportsForUserModalOpen, setCreateScheduledReportsForUserModalOpen] = React.useState(false);
	const [currentUserWhoseReportsAreBeingEdited, setCurrentUserWhoseReportsAreBeingEdited] = React.useState(null);

	const [userToOpen, setUserToOpen] = React.useState({} as UserRecord);
	const classes = UsersStyles();

	const firstRender = React.useRef(true);
	const [pagination, setPagination] = React.useState({ offset: 0, limit: props.globalRowsPerPage, page: 0 } as any);
	const [sortOrder, setSortOrder] = React.useState('');
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [tabView, setTabView] = React.useState('customer')

	const handleUsersSearch = (customerId?: any) => {
		setPagination({ offset: 0, limit: props.globalRowsPerPage, page: 0 });
	};

	const getPayload = (customerId?: any) => {
		const filterValues = props.selectedFilterValues || {};
		let payload: any = [];

		if (props.selectedCustomer) {
			payload.push({
				key: 'tenantId',
				value: props.selectedCustomer.customerId,
			});
		}

		if (filterValues['USER_FIRST_NAME']) {
			payload.push({
				key: 'firstName',
				value: filterValues['USER_FIRST_NAME'],
			});
		}

		if (filterValues['USER_LAST_NAME']) {
			payload.push({
				key: 'lastName',
				value: filterValues['USER_LAST_NAME'],
			});
		}

		if (filterValues['USER_EMAIL']) {
			payload.push({
				key: 'email',
				value: filterValues['USER_EMAIL'],
			});
		}

		payload.push({
			key: 'userType',
			value: tabView
		})

		if (sortOrder) {
			payload.push({
				key: 'order',
				value: sortOrder,
			});
		}

		payload.push({
			key: 'limit',
			value: props.globalRowsPerPage,
		});
		payload.push({
			key: 'offset',
			value: pagination.offset,
		});

		return payload;
	};

	const renderTenants = (value: any | null, tableMeta: any, updateValue: any) => {
		if (!value || Object.keys(value).length === 0) {
			return '';
		}

		let userType = initUserType(value);
		userType = userType === 'admin' ? 'customer' : userType;

		if (value.accessGroups) {
			return (
				<div>
					{value.accessGroups[userType].map((tenant: string, index: number) => {
						return (
							<Chip
								className={classes.chip}
								key={index}
								label={`${userType === 'customer' ? '' : userType + ':'}${tenant}`}
							/>
						);
					})}
				</div>
			);
		}

		return (
			<div>
				{Object.keys(value).map((tenant, index) => {
					return <Chip className={classes.chip} key={index} label={`${tenant}`} />;
				})}
			</div>
		);
	};

	const openUserModal = (dataIndex: number) => {
		if (!props.usersList) {
			return;
		}
		const user = props.usersList[dataIndex];
		setUserToOpen(user);
		setModalOpen(true);
	};

	const openEmailActivityModal = (dataIndex: number) => {
		if (!props.usersList) {
			return;
		}
		const user = props.usersList[dataIndex];
		setUserToOpen(user);
		setEmailActivityModalOpen(true);
	};

	const openResendInviteModal = (dataIndex: number) => {
		if (!props.usersList) {
			return;
		}
		const user = props.usersList[dataIndex];
		setUserToOpen(user);
		setResendInviteModalOpen(true);
	};

	const openSetPasswordModal = (dataIndex: number) => {
		if (!props.usersList) {
			return;
		}
		const user = props.usersList[dataIndex];
		setUserToOpen(user);
		setTempPasswordModalOpen(true);
	};

	const openDeleteUserModal = (dataIndex: number) => {
		if (!props.usersList) {
			return;
		}
		const user = props.usersList[dataIndex];
		setUserToOpen(user);
		setDeleteUserModalOpen(true);
	};

	const getUserModal = () => {
		if (
			props.authUser?.authorizationCheck({
				action: 'create',
				resource: 'users',
				tenantId: selectedTenant?.customerId ?? 'ALL',
			})
		) {
			return (
				<UserForm
					authUser={props.authUser}
					useFilters={true}
					thisUser={userToOpen}
					sortOrder={sortOrder}
					pagination={pagination}
					close={handleModalClose}
					openScheduledReportsForUserModal={openScheduledReportsForUserModal}
				/>
			);
		}
		return <UserDetails close={handleModalClose} thisUser={userToOpen} />;
	};

	const getImportModal = () => {
		return <UserImportForm close={handleImportModalClose} />;
	};

	const getEmailActivityModal = () => {
		return <UserEmailActivity close={handleEmailActivityModalClose} thisUser={userToOpen} />;
	};

	const getResendInviteModal = () => {
		return <ResendInvite close={handleResendInviteModalClose} thisUser={userToOpen} />;
	};

	const getSetPasswordModal = () => {
		return <SetPassword close={handleSetPasswordModalClose} thisUser={userToOpen} />;
	};

	const getDeleteUserModal = () => {
		return <DeleteUser close={handleDeleteUserModalClose} thisUser={userToOpen} />;
	};

	const handleModalOpen = () => {
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setUserToOpen({} as UserRecord);
		setModalOpen(false);
	};

	const handleImportModalOpen = () => {
		setImportModalOpen(true);
	};

	const handleImportModalClose = () => {
		setImportModalOpen(false);
	};

	const handleEmailActivityModalClose = () => {
		setEmailActivityModalOpen(false);
	};

	const handleResendInviteModalClose = () => {
		setResendInviteModalOpen(false);
	};

	const handleSetPasswordModalClose = () => {
		setTempPasswordModalOpen(false);
	};

	const handleDeleteUserModalClose = (reset: boolean = false) => {
		setDeleteUserModalOpen(false);
		if (reset) props.getUsersList(getPayload());
	};

	const handleScheduledReportsForUserModalClose = () => {
		setCreateScheduledReportsForUserModalOpen(false);
	};

	const ActionsMenu: React.FC<any> = (actionProps: any) => {
		const [menuOpen, setMenuOpen] = React.useState(false);
		const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
		if (!props.authUser?.authorizationCheck({ action: 'create', resource: 'users', tenantId: 'ALL' })) {
			return <></>;
		}
		return (
			<div>
				<IconButton
					aria-haspopup="true"
					aria-controls="long-menu"
					aria-label="more"
					style={{ padding: 0 }}
					onClick={e => {
						setMenuAnchor(e.currentTarget);
						setMenuOpen(!menuOpen);
					}}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					keepMounted
					id="long-menu"
					open={menuOpen}
					anchorEl={menuAnchor}
					MenuListProps={{ id: menuOpen ? 'shipment-actions' : '' }}
					PaperProps={{
						style: {
							height: 'auto',
							width: '20ch',
						},
					}}
					onClose={() => {
						setMenuOpen(false);
						setMenuAnchor(null);
					}}
				>
					<MenuItem
						onClick={() => {
							openUserModal(actionProps.dataIndex);
							setMenuOpen(false);
							setMenuAnchor(null);
						}}
						id={menuOpen ? 'edit-product' : ''}
					>
						Edit User
					</MenuItem>
					<MenuItem
						onClick={() => {
							openEmailActivityModal(actionProps.dataIndex);
							setMenuOpen(false);
							setMenuAnchor(null);
						}}
						id={menuOpen ? 'edit-product' : ''}
					>
						View Email Activity
					</MenuItem>
					<MenuItem
						onClick={() => {
							openResendInviteModal(actionProps.dataIndex);
							setMenuOpen(false);
							setMenuAnchor(null);
						}}
						id={menuOpen ? 'edit-product' : ''}
					>
						Resend Invite Email
					</MenuItem>
					{props.authUser?.authorizationCheck({ action: 'create', resource: 'users', tenantId: 'ALL' }) && (
						<MenuItem
							onClick={() => {
								openDeleteUserModal(actionProps.dataIndex);
								setMenuOpen(false);
								setMenuAnchor(null);
							}}
							id={menuOpen ? 'edit-product' : ''}
						>
							Delete User
						</MenuItem>
					)}
				</Menu>
			</div>
		);
	};

	const openScheduledReportsForUserModal = (userToEdit: any) => {
		console.log('opening reports editor for:', userToEdit);
		setCurrentUserWhoseReportsAreBeingEdited(userToEdit);
		setCreateScheduledReportsForUserModalOpen(true);
	};

	const getEditAnotherUsersReportsModal = (userToEdit: any) => {
		return <ScheduledReportsContainer thirdPartyUserBeingEdited={userToEdit} />;
	};

	const tableCols = [
		{
			name: 'email',
			label: 'Email',
			options: {
				filter: true,
				sort: true,
				display: tableStatePersist ? tableStatePersist.columns[0].display : true,
				sortOrder: null,
				change: false,
			},
		},
		{
			name: 'firstName',
			label: 'First Name',
			options: {
				filter: true,
				sort: true,
				display: tableStatePersist ? tableStatePersist.columns[1].display : true,
				sortOrder: null,
				change: false,
			},
		},
		{
			name: 'lastName',
			label: 'Last Name',
			options: {
				filter: true,
				sort: true,
				display: tableStatePersist ? tableStatePersist.columns[2].display : true,
				sortOrder: null,
				change: false,
			},
		},
		{
			name: 'lastLogin',
			label: 'Last Login',
			options: {
				sort: true,
				display: tableStatePersist ? tableStatePersist.columns[2].display : true,
				sortOrder: null,
				change: false,
				customBodyRender: (value: string) => {
					return value ? UserTimezoneFormat(props.properties, value) : 'UNKNOWN';
				},
			},
		},
		{
			name: 'claims',
			label: 'Customers',
			options: {
				customBodyRender: renderTenants,
				download: false,
				change: true,
				sort: false,
				display: tableStatePersist ? tableStatePersist.columns[3].display : true,
			},
		},
		{
			name: 'tenantsString',
			label: 'Tenants',
			options: {
				filter: false,
				display: false,
				viewColumns: false,
			},
		},
		{
			name: '',
			label: '',
			options: {
				display: true,
				change: false,
				viewColumns: false,
				customBodyRenderLite: (dataIndex: number) => ActionsMenu({ dataIndex: dataIndex }),
			},
		},
	];
	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			props.getUsersList(getPayload());
		} else {
			firstRender.current = false;
		}
	}, [pagination, sortOrder]);

	const handleTabChange = (event: any, newValue: string) => {
		setTabView(newValue)
	}

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			props.getUsersList(getPayload());
		} else {
			firstRender.current = false;
		}
	}, [tabView])

	return (
		<div>
			{!props.authUser && props.tenants.length === 0 ? (
				<div>
					<Loading message="" />
				</div>
			) : (
				<div>
					<div className={classes.searchWrap}>
						<Grid container>
							<Grid item xs={12}>
								<GlobalFiltersComponentContainer pageName="ADMIN_USERS" executeSearch={handleUsersSearch} />

								{props.authUser?.authorizationCheck({
									action: 'create',
									resource: 'users',
									tenantId: selectedTenant ? selectedTenant.customerId : 'ALL',
								}) && (
									<FormControl className={classes.formControl}>
										<Button
											className={classes.viewAllBtn}
											variant="contained"
											color="secondary"
											onClick={handleModalOpen}
										>
											Create New User
										</Button>
									</FormControl>
								)}
								{props.authUser?.authorizationCheck({ action: 'create', resource: 'users', tenantId: 'ALL' }) && (
									<FormControl className={classes.formControl}>
										<Button
											className={classes.viewAllBtn}
											variant="contained"
											color="secondary"
											onClick={handleImportModalOpen}
										>
											Import Users
										</Button>
									</FormControl>
								)}
							</Grid>
						</Grid>
					</div>

					{(!props.usersList || props.usersList.length === 0) && (
						<EmptyContent message="Select the filter criteria above to view associated users." />
					)}

					{props.usersList && props.usersList.length > 0 && (
						<>
							<AppBar position="static" color="default">
								<Tabs
									variant="scrollable"
									scrollButtons="on"
									aria-label="scrollable tabs"
									value={tabView}
									indicatorColor="secondary"
									textColor="primary"
									onChange={handleTabChange}
								>
									<Tab value="customer" label="Customers" />
									<Tab value="admin" label="Admins" />
								</Tabs>
							</AppBar>
							<MUIDataTable
								data={props.usersList ? generateDataList(props.usersList) : []}
								columns={tableCols}
								options={{
									rowsPerPage: props.globalRowsPerPage,
									rowsPerPageOptions: dataList.PageLimitOptions,
									onChangeRowsPerPage: (numberOfRows: number) => {
										setPagination({ ...pagination, limit: numberOfRows });
										props.setGlobalRowsPerPage(numberOfRows);
									},
									filterType: 'multiselect',
									selectableRows: 'none',
									onTableChange: (tableAction: any, tableState: any) => {
										console.log(tableAction);
										console.log(tableState);

										if (tableAction == 'changePage') {
											setPagination(tablePagination(tableAction, tableState, pagination));
										} else if (tableAction == 'sort') {
											setSortOrder(tableState.sortOrder.name + ' ' + tableState.sortOrder.direction);
										} else if (tableAction == 'viewColumnsChange') {
											setTableStatePersist(tableState);
										}
									},
									filter: false,
									search: false,
									serverSide: true,
									page: pagination.page,
									count: props.totalUsers,
									onDownload: () => {
										const timezone = props.authUser?.record?.properties
											? props.authUser?.record?.properties.find((property: any) => property.propertyKey === 'TIMEZONE')
													?.propertyValue
											: moment.tz.guess();
										const dateFormat = props.authUser?.record?.properties
											? props.authUser?.record?.properties.find((property: any) => property.propertyKey === 'DATEFORMAT')
													?.propertyValue
											: 'YYYY-MM-DD';
										let payload = getPayload();
										payload.push({
											key: 'csvExport',
											value: JSON.stringify({ timezone: timezone, dateFormat: dateFormat }),
										});
										payload.push({
											key: 'userType',
											value: tabView,
										});
										props.getUsersList(payload);
										return false;
									},
									fixedHeader: true, // This locks the table headers at the top
									tableBodyHeight: tableHeight + 'px', // Set the height for the table body
								}}
							/>
						</>
					)}
					<Modal
						open={modalOpen}
						onClose={handleModalClose}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<div>{getUserModal()}</div>
					</Modal>
					<Modal
						disableBackdropClick={true}
						open={importModalOpen}
						onClose={handleImportModalClose}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<div>{getImportModal()}</div>
					</Modal>
					<Modal
						open={emailActivityModalOpen}
						onClose={handleEmailActivityModalClose}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<div>{getEmailActivityModal()}</div>
					</Modal>
					<Modal
						open={resendInviteModalOpen}
						onClose={handleResendInviteModalClose}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<div>{getResendInviteModal()}</div>
					</Modal>
					<Modal
						open={setPasswordModalOpen}
						onClose={handleSetPasswordModalClose}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<div>{getSetPasswordModal()}</div>
					</Modal>
					<Modal
						open={deleteUserModalOpen}
						onClose={() => handleDeleteUserModalClose()}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<div>{getDeleteUserModal()}</div>
					</Modal>
					<Modal
						open={createScheduledReportsForUserModalOpen}
						onClose={() => handleScheduledReportsForUserModalClose()}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<div
							style={{
								maxHeight: 600,
								padding: 30,
								overflow: 'auto',
							}}
						>
							<IconButton
								aria-label="close"
								className={classes.closeButton}
								onClick={handleScheduledReportsForUserModalClose}
							>
								<CloseIcon />
							</IconButton>

							{getEditAnotherUsersReportsModal(currentUserWhoseReportsAreBeingEdited)}
						</div>
					</Modal>
				</div>
			)}
		</div>
	);
};
