import * as React from 'react';
import { connect } from 'react-redux';
import { TenantsActions, UIAction, OrderActions, ProductActions, UserActions } from '../../redux/actions';
import Loading from '../../components/loading';
import { AppBar, Button, FormControl, Grid, IconButton, Menu, MenuItem, Modal, TextField, TextareaAutosize, Tooltip } from '@material-ui/core';
import { CommonStyles, getModalClasses, getModalStyle } from '../../hooks/styles';
import MUIDataTable from 'mui-datatables';
import dataList from '../../constants/dataList';
import EmptyContent from '../../components/emptyContent';
import { tablePagination } from '../../hooks/functions/TablePagination';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AuthUser from '../../redux/lib/authUser';
import { PlotVariwideDragDropGuideBoxOptions } from 'highcharts';
import CustomerOrderRequestModal from './customer-order-request-modal';
import ModifyOrderRequestModal from '../order-management/modify-order-request.modal';
import ConfirmDialog from '../../components/dialog/confirm';
import NotesIcon from '@mui/icons-material/TextSnippet';

const ActionsMenu: React.FC<any> = props => {
	const [menuOpen, setMenuOpen] = React.useState(false);
	const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
	return (
		<div>
			<IconButton
				style={{ padding: 0 }}
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={e => {
					setMenuOpen(!menuOpen);
					setMenuAnchor(e.currentTarget);
				}}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={menuAnchor}
				keepMounted
				open={menuOpen}
				onClose={() => {
					setMenuOpen(false);
					setMenuAnchor(null);
				}}
				PaperProps={{
					style: {
						height: 'auto',
						width: '20ch',
					},
				}}
				MenuListProps={{ id: 'restock-order-actions' }}
			>
				{props.actions.modify && <MenuItem
					id={'modify-order-request'}
					onClick={() => {
						setMenuOpen(false);
						setMenuAnchor(null);
						props.actions.modify()
					}}
				>
					Modify
				</MenuItem>}
				{props.actions.delete && <MenuItem
						id={'delete-order-request'}
						onClick={() => {
							setMenuOpen(false);
							setMenuAnchor(null);
							props.actions.delete()
						}}
					>
						Delete
					</MenuItem>}
			</Menu>
		</div>
	);
};

interface OrderRequestsProps {
	selectedCustomer: any
	orderRequests: any[]
	totalOrderRequests: number
	authUser: AuthUser
	selectedFilterValues: any
	globalRowsPerPage: number
	properties: any[]
	page: string
	pagination: any
	sortOrder: any
	modalOpen: boolean
	selectedOrderRequests?: any[]
	setOrderRequestFilter?: (filter: any) => any
	getOrderRequests: (payload: any) => void
	updateOrderRequests: (payload: any) => void
	deleteOrderRequests: (payload: any) => void
	setGlobalRowsPerPage: (rowsPerPage: number) => void
	setPagination: (payload: any) => PlotVariwideDragDropGuideBoxOptions
	setSortOrder: (payload: any) => void
	setModalOpen: (value: boolean) => void
	setSelectedOrderRequests: (orderRequests: any[]) => void
}

const OrderRequestsComponent: React.FC<any> = (props: OrderRequestsProps) => {
	const firstRender = React.useRef(true);
	const [orderRequests, setOrderRequests] = React.useState(props.orderRequests)
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [selectedOrderRequest, setSelectedOrderRequest] = React.useState(null as any);
	const [selectedOrderRequestIndex, setSelectedOrderRequestIndex] = React.useState<number|null>(null);
	const [selectedTableRowIndexes, setSelectedTableRowIndexes] = React.useState<any[]>([])
	const [modalAction, setModalAction] = React.useState<string|null>(null)
	const [toolTopPosition, setToolTipPosition] = React.useState({x: 0, y: 0} as any);
	const [hoverData, setHoverData] = React.useState<any>(null);
	const [showToolTip, setShowToolTip] = React.useState(false);

	React.useEffect(() => {
		setOrderRequests(props.orderRequests)
	}, [props.orderRequests])

	const renderOrderRequestActions = (dataIndex: number) => {
		const thisOrderRequest = orderRequests[dataIndex]
		let menuConfig: any = {}
		if (props.page === 'orderManagement') {
			if (thisOrderRequest.status === 'PENDING') {
				menuConfig.modify = () => {
					props.setModalOpen(true);
					setModalAction('modify')
					setSelectedOrderRequest(thisOrderRequest);
					setSelectedOrderRequestIndex(dataIndex)
					console.log('DATAINDEX', dataIndex)
				}
			}
			if (thisOrderRequest.status !== 'PENDING') {
				menuConfig.delete = () => {
					props.setModalOpen(true);
					setModalAction('delete')
					setSelectedOrderRequest(thisOrderRequest);
				}
			}
			return Object.keys(menuConfig).length > 0 ? <ActionsMenu dataIndex={dataIndex} actions={menuConfig} /> : ''
		}
		if (props.page === 'customerOrderRequests' && thisOrderRequest.status === 'PENDING') {
			return <Tooltip title="Cancel Order Request">
				<IconButton style={{padding: 0}} onClick={() => {
					props.setModalOpen(true)
					setModalAction('cancel')
					setSelectedOrderRequest(thisOrderRequest);
				}}>
					<CancelIcon />
				</IconButton>
			</Tooltip>
		}
		return ''
	}

	const renderIcons: any = (dataIndex: number) => {
		return renderOrderRequestActions(dataIndex);
	};

	const columnData = [
		{
			label: 'Customer Id',
			name: 'customerId',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Product Id',
			name: 'productId',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Requested Qty',
			name: 'requestedQuantity',
			change: false,
			viewColumns: true,
			display: true,
		},
		{
			label: 'Approved Qty',
			name: 'approvedQuantity',
			change: false,
			viewColumns: true,
			display: true,
			customBodyRenderLite: (dataIndex: number) => {
				const orderRequest = orderRequests[dataIndex]
				if (orderRequest.status === 'PENDING') return 'approval pending'
				if (orderRequest.status === 'APPROVED') return orderRequest.approvedQuantity
				return 0
			}
		},
		{
			label: 'Shipping',
			name: 'shippingMethod',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'On Hand',
			name: 'onHandQuantity',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: '1 YR Usage',
			name: 'usage',
			change: false,
			viewColumns: false,
			sort: false,
			display: true,
			customBodyRender: (value?: any, tableMeta?: any, updateValue?: any) => {
				return value ? value : 0
			}
		},
		{
			label: 'Status',
			name: 'status',
			change: false,
			viewColumns: false,
			sort: true,
			display: true,
		},
		{
			label: 'Notes',
			name: 'customerNotes',
			change: false,
			viewColumns: false,
			display: true,
			customBodyRenderLite: (dataIndex: number) => {
				const orderRequest = orderRequests[dataIndex]
				const notesCount = ['customerNotes','adminNotes','orderNotes'].filter((notesType: string) => orderRequest[notesType] ? true : false).length
				if (notesCount === 0) {
					return 'No Notes'
				}
				return <NotesIcon 
					style={{marginLeft: 5}} 
					onMouseMove={(e: any) => {
						setToolTipPosition({x: e.pageX, y: e.pageY})
					}}
					onMouseEnter={(e: any) => {
						setHoverData(<React.Fragment>
							{orderRequest.customerNotes && <div>
								<b>Customer Notes: </b>{orderRequest.customerNotes}<br/><br/>
							</div>}
							{orderRequest.adminNotes && <div>
								<b>Admin Notes: </b>{orderRequest.adminNotes}<br/><br/>
							</div>}
							{orderRequest.orderNotes && props.page === 'orderManagement' && <div>
								<b>Order Notes: </b>{orderRequest.orderNotes}<br/><br/>
							</div>}
						</React.Fragment>)
						setShowToolTip(true)
					}}
					onMouseLeave={(e: any) => {
						setShowToolTip(false)
					}}
				/>
			}
		},
		
		{
			name: '',
			lable: '',
			display: true,
			change: false,
			viewColumns: false,
			customBodyRenderLite: (dataIndex: number) => {
				return renderIcons(dataIndex);
			},
		},
	];

	const getTableColumns = () => {
		let columns: any[] = columnData;
		if (props.page === 'orderManagement') {
			columns.splice(columns.length - 2, 0, {
				label: 'Batches',
				name: 'batches',
				display: true,
				sort: false,
				customBodyRender: (value?: any, tableMeta?: any, updateValue?: any) => {
					if (!value) {
						return 'None selected'
					}
					return value.map((batch: any) => (
						<>
							qty: {batch.quantity} - {batch.lot} - {batch.warehouse}<br/>
						</>
					))
				}
			})
		}

		const colData = columns.map((column: any, index: number) => {
			const display = column.display
			return {
				label: column.label,
				name: column.name,
				options: {
					filter: column.name ? true : false,
					display: tableStatePersist ? tableStatePersist.columns[index].display : display,
					customBodyRender: column.customBodyRender ? column.customBodyRender : undefined,
					customBodyRenderLite: column.customBodyRenderLite ? column.customBodyRenderLite : undefined,
					change: column.change,
					viewColumns: column.viewColumns,
					sort: column.sort ?? true,
				},
			};
		});

		return colData;
	};

	const getFilter = () => {
		const filter: any = {
			offset: props.pagination.offset,
			limit: props.globalRowsPerPage,
			where: {},
			order: props.sortOrder ? [props.sortOrder] : ['created_at DESC'],
		};
		const andFilters = []
		if (props.selectedCustomer) {
			andFilters.push({ customerId: props.selectedCustomer.customerId })
		}
		const status = props.selectedFilterValues?.ORDER_REQUEST_STATUS || null
		if (status) {
			andFilters.push({ status: status })
		}
		if (andFilters.length > 0) {
			filter.where.and = andFilters
		}
		props.setOrderRequestFilter && props.setOrderRequestFilter(filter)
		return filter;
	};

	const showLoadingMsg = () => !props.authUser && orderRequests.length === 0;

	React.useEffect(() => {
		console.log(firstRender.current)
		if (props.authUser && !firstRender.current) {
			const getOrderRequestsParams: any[] = [{key: 'filter', value: JSON.stringify(getFilter())}]
			props.getOrderRequests(getOrderRequestsParams)
			props.setSelectedOrderRequests && props.setSelectedOrderRequests([])
			setSelectedTableRowIndexes([])
		} else {
			firstRender.current = false;
		}
	}, [props.pagination, props.sortOrder]);

	React.useEffect(() => {
		if (props.selectedOrderRequests && props.selectedOrderRequests.length === 0) {
			setSelectedTableRowIndexes([])
		}
	}, [props.selectedOrderRequests])

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	const handleRowsSelected = (currentRowsSelected: any[], allRowsSelected: any[], rowsSelected: any[]) => {
		getFilter()
		let selections: any[] = []
		rowsSelected.forEach((selectedIndex: number) => {
			selections.push(orderRequests[selectedIndex])
		})
		setSelectedTableRowIndexes(rowsSelected)
		props.setSelectedOrderRequests(selections)
	}

	const handleCancelOrderRequest = () => {
		const requestToCancel = {...selectedOrderRequest, 
			status: 'CANCELLED',
			updatedBy: props.authUser.record.email,
			product: undefined,
			customer: undefined
		}

    props.updateOrderRequests({
      body: [requestToCancel],
      filter: [{key: 'filter', value: JSON.stringify(getFilter())}],
      complete: () => {
        props.setModalOpen(false);
				setModalAction('');
      }
    })
	}

	const handleDeleteOrderRequest = () => {
    props.deleteOrderRequests({
      orderRequestId: selectedOrderRequest.id, 
			filter: [{key: 'filter', value: JSON.stringify(getFilter())}],
      complete: () => {
        props.setModalOpen(false);
				setModalAction('');
      }
    })
	}

	return (
		<div>
			{showLoadingMsg() ? (
				<div>
					<Loading message="" />
				</div>
			) : (
				<div>
					{(orderRequests && orderRequests.length > 0) ? (
						<div>
							<MUIDataTable
								data={orderRequests}
								columns={getTableColumns()}
								options={{
									rowsPerPage: props.globalRowsPerPage,
									rowsPerPageOptions: dataList.PageLimitOptions,
									onChangeRowsPerPage: (numberOfRows: number) => {
										props.setPagination({ ...props.pagination, limit: numberOfRows });
										props.setGlobalRowsPerPage(numberOfRows);
									},
									onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
										// handleGetCsv(columns);
										return false;
									},
									filterType: 'dropdown',
									responsive: 'simple',
									filter: false,
									search: false,
									download: false,
									print: false,
									selectToolbarPlacement: 'none',
									selectableRows: props.page === 'orderManagement' ? 'multiple' : 'none',
									isRowSelectable: (dataIndex: number) => {
										return orderRequests[dataIndex].status === 'PENDING'
									},
									rowsSelected: props.page === 'orderManagement' ? selectedTableRowIndexes : undefined,
									onRowSelectionChange: props.page === 'orderManagement' ? handleRowsSelected : undefined,
									serverSide: true,
									page: props.pagination.page,
									count: props.totalOrderRequests,
									fixedHeader: true, // This locks the table headers at the top
									tableBodyHeight: tableHeight + 'px', // Set the height for the table body
									onTableChange: (tableAction: any, tableState: any) => {
										if (tableAction == 'changePage') {
											props.setPagination(tablePagination(tableAction, tableState, props.pagination));
										} else if (tableAction == 'sort') {
											props.setSortOrder(tableState.sortOrder.name + ' ' + tableState.sortOrder.direction);
											return;
										} else if (tableAction == 'viewColumnsChange') {
											setTableStatePersist(tableState);
										}
										if (!props.sortOrder) {
											tableState.sortOrder = {};
										}
									},
								}}
							/>
						</div>
					) : (
						<EmptyContent message="Change the filter criteria above to view order information." />
					)}
				</div>
			)}

			<Modal
				open={props.modalOpen}
				onClose={() => props.setModalOpen(false)}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					{!modalAction && <CustomerOrderRequestModal 
						pagination={props.pagination} 
						sortOrder={props.sortOrder}
						page={props.page}
						close={() => props.setModalOpen(false)}
					/>}
					{modalAction === 'modify' && <ModifyOrderRequestModal
						authUser={props.authUser}
						getFilter={getFilter}
						orderRequest={selectedOrderRequest}
						handleOrderRequestUpdate={(updatedOrderRequest: any) => {
							if (selectedOrderRequestIndex === null) return
							let orderRequestsCopy = orderRequests.map((or: any) => or)
							orderRequestsCopy[selectedOrderRequestIndex] = updatedOrderRequest
							setOrderRequests(orderRequestsCopy)
							props.setModalOpen(false)
						}} 
						close={() => props.setModalOpen(false)}
					/>}
				</div>
			</Modal>
			<ConfirmDialog
				open={modalAction === 'cancel' && props.modalOpen}
				handleClose={() => {
					props.setModalOpen(false)
					setModalAction('')
				}}
				title="Cancel This Order Request?"
				content="Once an order request is cancelled, this action cannot be undone."
				handleYes={() => {
					handleCancelOrderRequest()
				}}
			/>
			<ConfirmDialog
				open={modalAction === 'delete' && props.modalOpen}
				handleClose={() => {
					props.setModalOpen(false)
					setModalAction('')
				}}
				title="Delete This Order Request?"
				content="Once an order request is deleted, this action cannot be undone."
				handleYes={() => {
					handleDeleteOrderRequest()
				}}
			/>
			{showToolTip &&
				<div style={{
					position: 'absolute',
					top: toolTopPosition.y,
					left: toolTopPosition.x + 20,
					borderRadius: '5px',
					height: 'auto',
					width: 'auto',
					backgroundColor: '#444',
					opacity: 0.8,
					color: '#fff',
					padding: 10
				}}>
					{hoverData}
				</div>
			}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	orderRequests: state.orders.orderRequests ? state.orders.orderRequests.records : [],
	totalOrderRequests: state.orders.orderRequests ? state.orders.orderRequests.totalRecords.count : 0,
	authUser: state.user.authUser,
	selectedFilterValues: state.ui.selectedFilterValues,
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	properties: Array.isArray(state.user?.authUser?.record?.properties) ? state.user.authUser.record.properties : [],
});

const mapDispatchToProps = (dispatch: any) => ({
	getOrderRequests: (payload: any) => dispatch(OrderActions.getOrderRequests(payload)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
	updateOrderRequests: (payload: any) => dispatch(OrderActions.updateOrderRequests(payload)),
	deleteOrderRequests: (payload: any) => dispatch(OrderActions.deleteOrderRequest(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderRequestsComponent);
